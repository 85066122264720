import * as React from 'react';
import { render } from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { configureStore, history } from '../redux/configureStore';
import Routes from './Routes';
import appModule from '../redux/module';

const store = configureStore();

window.tle = { store };

const App = () => render(
  <Provider store={store} context={ReactReduxContext}>
    <DynamicModuleLoader modules={[appModule]}>
      <ConnectedRouter history={history} context={ReactReduxContext}>
        <Routes />
      </ConnectedRouter>
    </DynamicModuleLoader>
  </Provider>,
  document.getElementById('react-app'),
);
App();
export default App;
