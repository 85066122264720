import { preloadState } from './thunks';
import userReducer from '../login/state/userReducer';
import gamingReducer from '../gamingActivity/state/gamingReducer';
import financialReducer from '../financialTransactions/state/financialReducer';
import resultsReducer from '../results/state/resultsReducer';

const appModule = () => ({
  // Unique id of the module
  id: 'tle-regulation-australia-audit-log-viewer-microsite',
  // Maps the Store key to the reducer
  reducerMap: {
    currentUser: userReducer,
    selectedTab: resultsReducer,
    gamingActivity: gamingReducer,
    financialTransactions: financialReducer,
  },
  initialActions: [
    preloadState(),
  ],
});

export default appModule;
