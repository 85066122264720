/* eslint-disable indent */
import gamingActivityActionTypes from './gamingActionTypes';
import initialState from '../../redux/initialState';

const gamingReducer = (state = initialState.gamingData, action) => {
    switch (action.type) {
        case gamingActivityActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_WEB_GAMING_SEARCH_SUCCESS:
            {
                return {
                    ...state,
                    page: action.data.page,
                    pageSize: action.data.pageSize,
                    totalRecords: action.data.totalRecords,
                    data: action.data.data,
                    criteria: action.data.criteria,
                    sortBy: action.data.sortBy,
                    sortDirection: action.data.sortDirection,
                };
            }
        default:
            return state;
    }
};

export default gamingReducer;
