import { ClientLogger } from '@tle/logger-client';
import fragmentName from '../consts';

// try to get logging url from the windows if run on server VIA SSR return empty string.
const getLoggingURL = () => {
  // SSR does not need loggingURL this value is just for  the client, so we set it to dummy URL.
  let loggingURL = '';
  if (typeof window !== 'undefined') { // check context is  browser
    loggingURL = window.TLE.LOGGING_URL;
  }
  return loggingURL;
};
const logger = new ClientLogger({ prefix: fragmentName, loggingURL: getLoggingURL() });
export default logger;
