/* eslint-disable import/prefer-default-export */
import { StateConfig } from '@tle/core';
import { preloadStateSuccess } from './actionCreators';
import fragmentName from '../consts';

const preloadState = () => function preload(dispatch) {
  const fragmentState = new StateConfig(fragmentName);
  const preloadedState = fragmentState.preloadedStateDOM();
  const state = { ...preloadedState };
  dispatch(preloadStateSuccess({ state }));
};

export { preloadState };
