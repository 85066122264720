import React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './Layout';
import Home from './Home';
import Results from '../results/Results';
import LoginPage from '../login/LoginPage';
import AuthenticatedRoute from '../login/AuthenticatedRoute';
import FinancialTransactionsDetails from '../financialTransactions/FinancialTransactionsDetails';
import GamingActivityDetails from '../gamingActivity/GamingActivityDetails';

const Routes = () => (
  <Layout>
    <Switch>
      <AuthenticatedRoute path="/results/financial-transaction/:sequentialNumber">
        <FinancialTransactionsDetails />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/results/gaming-activity/:sequentialNumber">
        <GamingActivityDetails />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/results">
        <Results />
      </AuthenticatedRoute>
      <Route path="/login" component={LoginPage} />
      <Route exact path="/" component={Home} />
    </Switch>
  </Layout>
);

export default Routes;
