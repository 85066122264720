import React from 'react';
import PropTypes from 'prop-types';
import {
  Panel, PanelContent, Block, Tabs, Tab, TabList, TabPanel,
} from '@tle/ui';
import { connect } from 'react-redux';
import FinancialTransactions from '../financialTransactions/FinancialTransactions';
import GamingActivity from '../gamingActivity/GamingActivity';
import { changeSelectedTab } from './state/resultsActions';

const Results = ({ selectedTab, changeTab }) => {
  const onTabSelect = (selected) => {
    changeTab(selected);
  };

  return (
    <Block>
      <Block color="standard" padding="2% 5% 15% 5%">
        <Panel color="white" margin="auto" opacity="highest" radius="normal" shadow="small" overflow="auto" padding="0px">
          <PanelContent>
            <Tabs
              defaultIndex={0}
              selectedColor="primary"
              selectedIndex={selectedTab}
              onSelect={onTabSelect}
            >
              <TabList selectedColor="primary">
                <Tab title="Game Activity" />
                <Tab title="Financial transactions" />
              </TabList>
              <TabPanel>
                <GamingActivity />
              </TabPanel>
              <TabPanel>
                <FinancialTransactions />
              </TabPanel>
            </Tabs>
          </PanelContent>
        </Panel>
      </Block>
    </Block>
  );
};

Results.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedTab: state.selectedTab,
});

const mapDispatchToProps = {
  changeTab: changeSelectedTab,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Results);
