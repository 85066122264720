
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SearchResults } from '@tle/ui-organisms';
import { useHistory } from 'react-router-dom';
import { searchGamingActivity, exportGamingActivity } from './state/gamingActions';

const GamingActivity = ({
  searchByParams, gamingActivityData, exportToCsv,
}) => {
  const history = useHistory();

  const onRowClick = (row) => {
    history.push(`/results/gaming-activity/${row.sequential_number}`);
  };

  return (
    <SearchResults
      onRowClick={onRowClick}
      searchByParams={searchByParams}
      exportToCsv={exportToCsv}
      resultData={gamingActivityData}
    />
  );
};

GamingActivity.propTypes = {
  searchByParams: PropTypes.func.isRequired,
  exportToCsv: PropTypes.func.isRequired,
  gamingActivityData: PropTypes.oneOf([PropTypes.object.isRequired]),
};

const mapStateToProps = state => ({
  gamingActivityData: state.gamingActivity,
});

const mapDispatchToProps = {
  searchByParams: searchGamingActivity,
  exportToCsv: exportGamingActivity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GamingActivity);
