import { Route, Redirect } from 'react-router';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from './state/userActions';

const AuthenticatedRoute = ({ children, isLoggedIn, ...props }) => (
  <Route
    {...props}
    render={() => (isLoggedIn() ? (
      children
    ) : (
      <Redirect to="/login" />
    ))
      }
  />
);

AuthenticatedRoute.propTypes = {
  children: PropTypes.node,
  isLoggedIn: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  isLoggedIn: userActions.isAuthenticated,
};
export default connect(null, mapDispatchToProps)(AuthenticatedRoute);
