import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SearchResults } from '@tle/ui-organisms';
import { useHistory } from 'react-router-dom';
import { searchFinancialTransactions, exportFinancialTransactions } from './state/financialActions';


const FinancialTransactions = ({
  searchByParams, resultData, exportToCsv,
}) => {
  const history = useHistory();

  const onRowClick = (row) => {
    history.push(`/results/financial-transaction/${row.sequential_number}`);
  };

  return (<SearchResults onRowClick={onRowClick} screenType="financial" searchByParams={searchByParams} exportToCsv={exportToCsv} resultData={resultData} />);
};

const mapStateToProps = state => ({
  resultData: state.financialTransactions,
});

FinancialTransactions.propTypes = {
  searchByParams: PropTypes.func.isRequired,
  exportToCsv: PropTypes.func.isRequired,
  resultData: PropTypes.oneOf([PropTypes.object.isRequired]),
};
const mapDispatchToProps = {
  searchByParams: searchFinancialTransactions,
  exportToCsv: exportFinancialTransactions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinancialTransactions);
