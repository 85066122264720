import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore } from 'redux-dynamic-modules';
import { getThunkExtension } from 'redux-dynamic-modules-thunk';
import { applyMiddleware, compose } from 'redux';
import appModule from './module';

window.TLE = window.TLE || {};
// set the logging url in the state
window.TLE.LOGGING_URL = '/logs';

const initialState = window.initialReduxState || {};
const history = createBrowserHistory();

const routerModule = () => ({
  id: 'router',
  reducerMap: {
    router: connectRouter(history),
  },
  middlewares: [routerMiddleware(history)],
  retained: true,
});

const configureStore = (preloadedState = initialState) => {
  const store = createStore(
    preloadedState,
    [compose(applyMiddleware(routerMiddleware(history)))],
    [getThunkExtension()],
  );

  store.addModule(routerModule());
  store.addModule(appModule());
  return store;
};

export { history, configureStore };
