import React from 'react';
import { ThemeProvider } from 'styled-components';
import { defaultTheme, PictureLayout, Block } from '@tle/ui';
import PropTypes from 'prop-types';
import backgroundImg from '../images/desktop-backgroundimage-login.png';
import backgroundImg2x from '../images/desktop-backgroundimage-login@2x.png';
import backgroundImg3x from '../images/desktop-backgroundimage-login@3x.png';
import HeaderPage from './HeaderPage';

const Layout = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>
    <Block overflow="scroll">
      <PictureLayout
        src={backgroundImg3x}
        sources={[
          {
            src: backgroundImg,
            media: '(max-width: 600px)',
          },
          {
            src: backgroundImg2x,
            media: '(min-width: 800px)',
          },
        ]}
      >
        <HeaderPage />
        {children}
      </PictureLayout>
    </Block>
  </ThemeProvider>
);
Layout.propTypes = { children: PropTypes.node };

export default Layout;
