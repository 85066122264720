export default {
  currentUser: JSON.parse(localStorage.getItem('currentUser')),
  selectedTab: 0,
  gamingData: {
    page: 0,
    pageSize: 10,
    totalRecords: 0,
    sortBy: 'orderDate',
    sortDirection: 'desc',
    criteria: null,
    data: [],
  },
  financialData: {
    page: 0,
    pageSize: 10,
    totalRecords: 0,
    sortBy: 'transactionDate',
    sortDirection: 'desc',
    criteria: null,
    data: [],
  },
};
