import React, { useState, useEffect } from 'react';
import {
  Panel, PanelContent, Block,
} from '@tle/ui';
import { SearchDetails } from '@tle/ui-organisms';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import StatusCodes from 'http-status-codes';
import { history } from '../redux/configureStore';
import NoData from '../common/NoData';
import * as userActions from '../login/state/userActions';

const GamingActivityDetails = () => {
  const [loading, setLoading] = useState(true);
  const { sequentialNumber } = useParams();
  const [details, setDetails] = useState(null);

  useEffect(() => {
    async function loadGamingActivity() {
      try {
        const response = await axios.get(`/api/v1/gaming-activity/search/${sequentialNumber}`);
        if (response && response.data) {
          setDetails(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === StatusCodes.UNAUTHORIZED) {
          localStorage.removeItem('currentUser');
          userActions.logOutUser();
          window.location.reload();
        } else {
          throw error;
        }
      }
      setLoading(false);
    }
    loadGamingActivity();
  }, [sequentialNumber]);

  const onClickBack = () => {
    history.push('/results');
  };

  const displayResult = () => (
    !details
      ? <NoData />
      : (
        <SearchDetails
          onClickBack={onClickBack}
          itemType="GamingActivity"
          id={details.id}
          licenceIdentifier={details.licenseIdentifier}
          clientAccount={details.clientAccount}
          clientName={details.clientName}
          clientLocation={details.clientLocation}
          clientIpAddress={details.clientIpAddress}
          gamingChannel={details.gamingChannel}
          gamingChannelName={details.gamingChannelName}
          orderNumber={details.orderNumber}
          orderDescription={details.orderDescription}
          purchaseAmount={details.purchaseAmount}
          orderDate={details.orderDate}
          orderStatus={details.orderStatus}
          orderStatusDate={details.orderStatusDate}
          selectedNumbers={details.selectedNumbers}
        />
      )
  );

  return (
    <Block color="standard" padding="2% 5% 15% 5%">
      <Panel color="white" margin="auto" opacity="highest" radius="normal" shadow="small" overflow="auto" padding="24px 40px">
        <PanelContent>
          {loading
            ? <div>Loading....</div>
            : displayResult()}
        </PanelContent>
      </Panel>
    </Block>
  );
};
export default GamingActivityDetails;
