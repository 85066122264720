import userActionTypes from './userActionTypes';
import { history } from '../../redux/configureStore';

function beginLoginRequest() {
  return { type: userActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_LOGIN_REQUEST };
}

function loginSuccess(currentUser) {
  return { type: userActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_LOGIN_SUCCESS, currentUser };
}

function getUser(currentUser) {
  return { type: userActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_USER_GET_CURRENT_USER, currentUser };
}

function logOut(currentUser) {
  return { type: userActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_LOGOUT, currentUser };
}

function loginUser(user) {
  return (dispatch) => {
    dispatch(beginLoginRequest());
    const currentUser = {};
    const response = { currentUser, error: '' };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user),
    };
    return fetch('/api/v1/login', requestOptions)
      .then(res => res.json())
      .then((res) => {
        response.currentUser.firstName = res.firstName;
        response.currentUser.lastName = res.lastName;
        response.error = res.error;
      })
      .then(() => {
        if (!response.error) {
          dispatch(loginSuccess(currentUser));
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          history.push('/results');
          return currentUser;
        }
        return response;
      });
  };
}

function getCurrentUser() {
  return (dispatch) => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    dispatch(getUser(currentUser));
    return currentUser;
  };
}

function isAuthenticated() {
  return () => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser != null && currentUser !== undefined;
  };
}

function logOutUser() {
  return (dispatch) => {
    localStorage.removeItem('currentUser');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    };
    fetch('/api/v1/logout', requestOptions);
    dispatch(logOut());
    history.push('/login');
  };
}

export {
  loginUser, getCurrentUser, logOutUser, isAuthenticated,
};
