import { EmptyResults, Image } from '@tle/ui';
import React from 'react';
import notFoundImage from '../images/not-result-found-character.png';

const NoData = () => (
  <EmptyResults
    textSizes="large"
    title="Oops..."
    subtitle="An error has occurred, please try again later"
    image={(
      <Image
        width="auto"
        height="auto"
        radius="none"
        src={notFoundImage}
        srcSet={[
          {
            pixelsDensity: '1x',
            src: notFoundImage,
          },
        ]}
      />
    )}
  />
);

export default NoData;
