import resultsActionTypes from './resultsActionTypes';

export function changeSelectedTabSuccess(data) {
  return { type: resultsActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_WEB_CHANGE_SELECTED_TAB_SUCCESS, data };
}

export const changeSelectedTab = params => async (dispatch) => {
  dispatch(changeSelectedTabSuccess(params));
  return true;
};
