/* eslint-disable indent */
import financialTransactionsActionTypes from './financialActionTypes';
import initialState from '../../redux/initialState';

const financialReducer = (state = initialState.financialData, action) => {
    switch (action.type) {
        case financialTransactionsActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_WEB_FINANCIAL_SEARCH_SUCCESS:
            {
                return {
                    ...state,
                    page: action.data.page,
                    pageSize: action.data.pageSize,
                    totalRecords: action.data.totalRecords,
                    data: action.data.data,
                    criteria: action.data.criteria,
                    sortBy: action.data.sortBy,
                    sortDirection: action.data.sortDirection,
                };
            }
        default:
            return state;
    }
};

export default financialReducer;
