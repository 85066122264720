import axios from 'axios';
import * as userActions from '../../login/state/userActions';
import financialTransactionsActionTypes from './financialActionTypes';
import { history } from '../../redux/configureStore';

const { StatusCodes } = require('http-status-codes');

export function loadFinancialDataSuccess(data) {
  return { type: financialTransactionsActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_WEB_FINANCIAL_SEARCH_SUCCESS, data };
}

export const searchFinancialTransactions = params => async (dispatch) => {
  try {
    const response = await axios.post('/api/v1/financial-transactions/search', { params });
    dispatch(loadFinancialDataSuccess(response.data));
  } catch (error) {
    if (error.response && error.response.status === StatusCodes.UNAUTHORIZED) {
      localStorage.removeItem('currentUser');
      userActions.logOutUser();
      history.push('/');
      window.location.reload();
    } else {
      throw error;
    }
  }

  return true;
};

export const exportFinancialTransactions = params => async () => {
  try {
    const response = await axios.post('/api/v1/financial-transactions/export', { params });

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'financial_transactions_records.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    if (error.response && error.response.status === StatusCodes.UNAUTHORIZED) {
      localStorage.removeItem('currentUser');
      userActions.logOutUser();
      history.push('/');
      window.location.reload();
    } else {
      throw error;
    }
  }

  return true;
};
