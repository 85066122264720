import userActionTypes from './userActionTypes';
import initialState from '../../redux/initialState';

export default function userReducer(state = initialState.currentUser, action) {
  switch (action.type) {
    case userActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_LOGIN_SUCCESS:
    {
      const { currentUser } = action;
      return currentUser;
    }
    case userActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_USER_GET_CURRENT_USER:
    {
      const { currentUser } = action;
      return currentUser;
    }
    case userActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_LOGOUT:
    {
      const { currentUser } = action;
      return { currentUser };
    }
    default:
      return state;
  }
}
