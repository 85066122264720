import React, { useState, useEffect } from 'react';
import {
  Panel, PanelContent, Block,
} from '@tle/ui';
import { SearchDetails } from '@tle/ui-organisms';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import logger from '../utils/logger';
import { history } from '../redux/configureStore';
import NoData from '../common/NoData';

const FinancialTransactionsDetails = () => {
  const [loading, setLoading] = useState(true);
  const { sequentialNumber } = useParams();
  const [financialTransaction, setFinancialTransaction] = useState(null);

  useEffect(() => {
    async function loadTransactionDetails() {
      try {
        const response = await axios.get(`/api/v1/financial-transactions/search/${sequentialNumber}`);
        if (response && response.data) {
          setFinancialTransaction(response.data);
        }
      } catch (error) {
        logger.error('Failed loading financial transactions by id', sequentialNumber, error);
      }
      setLoading(false);
    }
    loadTransactionDetails();
  }, [sequentialNumber]);

  const onClickBack = () => {
    history.push('/results');
  };

  const displayResult = () => (
    !financialTransaction
      ? <NoData />
      : (
        <SearchDetails
          onClickBack={onClickBack}
          itemType="FinancialTransaction"
          id={financialTransaction.id}
          licenceIdentifier={financialTransaction.licenseIdentifier}
          clientAccount={financialTransaction.clientAccount}
          clientName={financialTransaction.clientName}
          clientLocation={financialTransaction.clientLocation}
          clientIpAddress={financialTransaction.clientIpAddress}
          transactionType={financialTransaction.transactionType}
          transactionComment={financialTransaction.transactionComment}
          transactionAmount={financialTransaction.transactionAmount}
          transactionDate={financialTransaction.transactionDate}
          transactionStatus={financialTransaction.transactionStatus}
          transactionStatusDate={financialTransaction.transactionStatusDate}
          timeZone={financialTransaction.timeZone}
          accountBalance={financialTransaction.accountBalance}
        />
      )
  );

  return (
    <Block color="standard" padding="2% 5% 15% 5%">
      <Panel color="white" margin="auto" opacity="highest" radius="normal" shadow="small" overflow="auto" padding="24px 40px">
        <PanelContent>
          {loading
            ? <div>Loading....</div>
            : displayResult()}
        </PanelContent>
      </Panel>
    </Block>
  );
};

export default FinancialTransactionsDetails;
