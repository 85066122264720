/* eslint-disable indent */
import resultsActionTypes from './resultsActionTypes';
import initialState from '../../redux/initialState';

const resultsReducer = (state = initialState.selectedTab, action) => {
    switch (action.type) {
        case resultsActionTypes.TLE_REGULATION_AUSTRALIA_AUDIT_LOG_VIEWER_WEB_CHANGE_SELECTED_TAB_SUCCESS:
            {
                return action.data;
            }
        default:
            return state;
    }
};

export default resultsReducer;
