import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AustraliaHeader } from '@tle/ui-organisms';
import * as userActions from '../login/state/userActions';
import logo from '../images/lotter-australia-logo.png';
import logo2x from '../images/lotter-australia-logo@2x.png';
import logo3x from '../images/lotter-australia-logo@3x.png';

const translations = {
  hello: 'Hello',
  logOut: 'Log out',
};

const srcSet = [
  {
    pixelsDensity: '1x',
    src: logo,
  },
  {
    pixelsDensity: '2x',
    src: logo2x,
  },
  {
    pixelsDensity: '3x',
    src: logo3x,
  },
];

const userShape = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
});

const HeaderPage = ({ logOutAction, currentUser }) => {
  return (
    <AustraliaHeader
      currentUser={currentUser}
      logOutAction={logOutAction}
      translations={translations}
      logoSrcSet={srcSet}
    />
  );
};

HeaderPage.propTypes = {
  logOutAction: PropTypes.func.isRequired,
  currentUser: userShape,
};

function mapStateToProps(state) {
  const { currentUser } = state;
  if (currentUser) {
    return {
      currentUser,
    };
  }
  return null;
}
const mapDispatchToProps = {
  getUser: userActions.getCurrentUser,
  logOutAction: userActions.logOutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPage);
