import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Block, Box, BoxItem, Panel, PanelContent,
} from '@tle/ui';
import { AustraliaLogin } from '@tle/ui-organisms';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import * as userActions from './state/userActions';
import loginImage from '../images/loginImage.png';
import loginImage2x from '../images/loginImage@2x.png';
import loginImage3x from '../images/loginImage@3x.png';

const translations = {
  title: 'Login',
  username: 'Username',
  password: 'Password',
  login: 'Login',
  invalidCredentials: 'The username or password did not match our records. Please try again!',
};
const srcSet = [
  {
    pixelsDensity: '1x',
    src: loginImage,
  },
  {
    pixelsDensity: '2x',
    src: loginImage2x,
  },
  {
    pixelsDensity: '3x',
    src: loginImage3x,
  },
];
const UserShape = PropTypes.shape({
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
});

const LoggedIn = () => (
  <Redirect to="/results" />
);

const NotLoggedIn = ({
  handleSubmit, validateForm, userLogin, isFormValid, error,
}) => (
  <Block color="standard" height="auto" margin="auto" width="auto" padding="5%">
    <Box alignContent="stretch" alignItems="center" direction="column">
      <BoxItem basis="auto" overflow="auto">
        <Panel width="848px" height="395px" color="white" margin="auto" opacity="highest" radius="normal" shadow="small" overflow="auto" padding="0px">
          <PanelContent>
            <AustraliaLogin
              onSubmit={handleSubmit}
              validateForm={validateForm}
              usernameValue={userLogin.username}
              passwordValue={userLogin.password}
              canSubmit={isFormValid}
              error={error}
              translations={translations}
              loginImageSrcSet={srcSet}
            />
          </PanelContent>
        </Panel>
      </BoxItem>
    </Box>
  </Block>
);

NotLoggedIn.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool,
  error: PropTypes.string,
  userLogin: UserShape,
};

const LoginPage = ({ login, isLoggedIn }) => {
  const [userLogin] = useState({
    username: '', password: '',
  });
  const [error, setError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmit = (loginInfo) => {
    login(loginInfo).then((res) => {
      if (res.error) {
        setError(translations[res.error]);
      }
    })
      .catch((er) => {
        setError(er.message);
      });
  };

  const validateForm = (input) => {
    setIsFormValid(input.username && input.password);
  };

  return (
    isLoggedIn()
      ? <LoggedIn />
      : (
        <NotLoggedIn
          validateForm={validateForm}
          handleSubmit={handleSubmit}
          isFormValid={isFormValid}
          userLogin={userLogin}
          error={error}
        />
      )
  );
};

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const userLogin = {
    username: '', password: '',
  };
  const loginError = state.LoginError;

  return {
    userLogin, loginError,
  };
}

const mapDispatchToProps = {
  login: userActions.loginUser,
  isLoggedIn: userActions.isAuthenticated,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
